import 'styles/globals.css'
import 'styles/style.scss'
import { useEffect } from 'react'
import type { AppProps } from 'next/app'
import NextNprogress from 'nextjs-progressbar'
import { DefaultSeo } from 'next-seo'
import { RecoilRoot } from "recoil"
import { useRecoilState } from 'recoil'
import { userState } from 'recoil/atoms'
import { fetcher } from 'functions/fetcher/fetcher'

function AppInit() {

  const [user, setUser] = useRecoilState(userState)

  useEffect(() => {
    const FetchUser = async () => {
      const url = process.env.NEXT_PUBLIC_BACKEND + 'auth/me/'

      try {
        const res = await fetcher(url)
        const json = await res.json()
        const result = await json.result
        setUser(result.user)
      } catch {
        setUser(null)
      }
    }
    FetchUser()
  },[])

  return null
}

function MyApp({ Component, pageProps }: AppProps) {

  return (
    <RecoilRoot>
      <DefaultSeo
        openGraph={{
          images: [
            {url: "https://debicre.com/static/logos/debicre.png"}
          ]
        }}
        twitter={{
          handle: '@Debicre_boki',
          site: '@Debicre_boki',
          cardType: 'summary',
        }}
      />
      <NextNprogress
        color="#0095FE"
        options={{ showSpinner: false }}
      />
      <Component {...pageProps} />
      <AppInit />
    </RecoilRoot>
  )
}
export default MyApp
